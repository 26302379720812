<template>
  <div v-if="!loading">
    <h1 class="main-header">
      <translate translate-context="contract" :translate-params="{id: contract.id, name: contract.name}">Edit Contract
        #%{id} - %{name}
      </translate>
    </h1>
    <CCard>
      <CCardHeader>
        <div class="card-header-actions">
          <CButton variant="outline" color="success" @click="onReset" ref="reset-button" v-translate
                   translate-context="form" class="mr-2">Reset
          </CButton>
          <CButton color="primary" @click="onSave" ref="save-button">Save</CButton>
        </div>
      </CCardHeader>
      <CCardBody class="p-0">
        <CContainer fluid class="grid-stripped">
          <PFRow :label="$pgettext('contract_manager.label', 'Qty')">
            <CInput v-model="form.qty" type="number"/>
          </PFRow>
          <PFRow :label="$pgettext('contract.create.label','Template')" size="12x12">
            <PSelectKeyValue :options="searchTemplate" :value.sync="form.template" placeholder="Select Template"/>
          </PFRow>
          <PFRow :label="$pgettext('contract.create.label','Vertical')" size="12x12">
            <PSelectKeyValue :options="searchVertical" :value.sync="form.vertical" placeholder="Select Vertical"/>
          </PFRow>
          <PFRow :label="$pgettext('contract.create.label','Terzo Dominio ( ex Event Owner )')" size="12x12">
            <PSelectKeyValue :options="searchEventOwner" :value.sync="form.event_owner"
                             placeholder="Select Event owner"/>
          </PFRow>
          <PFRow :label="$pgettext('contract_manager.label', 'Name')">
            <CInput v-model="form.name"/>
          </PFRow>
          <PFRow :label="$pgettext('contract_manager.label', 'Is Subscription')">
            <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.is_subscription"/>
          </PFRow>
          <template v-if="!form.is_subscription">
            <PFRow :label="$pgettext('contract_manager.label', 'Num Photo Included')">
              <CInput v-model="form.plan.num_photo_included" type="number"/>
            </PFRow>
            <PFRow :label="$pgettext('contract_manager.label', 'Num Max Photo')">
              <CInput v-model="form.plan.num_max_photo" type="number"/>
            </PFRow>
            <PFRow :label="$pgettext('contract_manager.label', 'Max Participants')">
              <CInput v-model="form.plan.max_participants" type="number"/>
            </PFRow>
            <PFRow :label="$pgettext('contract_manager.label', 'Extra Photo Price')">
              <CInput v-model="form.plan.extra_photo_price" type="number"/>
            </PFRow>
            <PFRow :label="$pgettext('contract_manager.label', 'Is Promo')">
              <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.plan.is_promo"/>
            </PFRow>
          </template>
          <template v-else>
            <PFRow :label="$pgettext('contract_manager.label', 'Num Photo Included')">
              <CInput v-model="form.settings.num_photo_included" type="number"/>
            </PFRow>
            <PFRow :label="$pgettext('contract_manager.label', 'Num Max Photo')">
              <CInput v-model="form.settings.num_max_photo" type="number"/>
            </PFRow>
            <PFRow :label="$pgettext('contract_manager.label', 'Is Promo')">
              <CSwitch class="mx-1" color="primary" variant="3d" size="sm" :checked.sync="form.settings.is_promo"/>
            </PFRow>
          </template>
        </CContainer>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {RecordNotFound, SuccessNotification} from "@/domain/core/exception/exceptions";
import PFRow from "@/domain/core/components/PFRow.vue";
import PSelectKeyValue from "@/domain/core/components/PSelectKeyValue.vue";

export default {
  name: 'EditContract',
  components: {PSelectKeyValue, PFRow},
  data() {
    return {
      loading: true,
      form: {
        qty: null,
        template: '',
        vertical: '',
        event_owner: '',
        name: '',
        is_subscription: false,
        plan: {
          num_photo_included: null,
          num_max_photo: null,
          max_participants: null,
          extra_photo_price: null,
          is_promo: false,
        },
        settings: {
          num_photo_included: null,
          num_max_photo: null,
          is_promo: null,
        },
      },
    }
  },
  async mounted() {
    try {
      await this.fetchContract({
        id: this.$route.params.id,
        contract_manager: this.$route.params.contract_manager
      })
      this.onReset()
    } catch (e) {
      if (e instanceof RecordNotFound) {
        await this.$router.push({name: 'contract_manager.detail', params: {id: this.$route.params.contract_manager}})
      }
    }
    this.loading = false
  },
  computed: {
    ...mapGetters('contractManager', [
      'contract',

    ]),
  },
  methods: {
    ...mapActions('contractManager', [
      'fetchContract',
      'updateContract',
      'searchEventOwner',
      'searchTemplate',
      'searchVertical',
    ]),
    async onSave() {
      await this.updateContract({
        contract_manager: this.$route.params.contract_manager,
        id: this.contract.id,
        data: this.form,
      })
      await this.$router.push({name: 'contract_manager.detail', params: {id: this.$route.params.contract_manager}})
      throw new SuccessNotification(this.$pgettext('contract', 'Contract updated!'))
    },
    onReset() {
      this.form = {...this.contract}
    }
  }
}
</script>

<style scoped>

</style>